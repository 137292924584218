import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { MultiLineValidationTypes, MultilinesItem } from '../../../../../shared/models/survey-items/question-items/multilinesItem';
import { AutocompleteListProvider } from '../../../../providers';

@Component({
    selector: 'cb-multilines-item',
    templateUrl: './multilines-item.component.html',
    styleUrls: ['./multilines-item.component.scss']
})
@AutoUnsubscribe()
export class MultilinesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: MultilinesItem;

    multiLineText = SurveyQuestionType.MULTI_LINE_TEXT;
    form: FormGroup;
    autocompleteLists: any[];
    list: string;
    itemPosition: string;
    textPosition: string;
    allow_html: boolean;
    types = Object.entries(MultiLineValidationTypes);
    validation_type: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private autocompleteListProvider: AutocompleteListProvider
    ) {}

    ngOnInit() {
        this.validation_type = this.questionItem.validation_type || this.types[0][0];
        this.autocompleteListProvider.getLists().subscribe(c => {
            this.autocompleteLists = c;
        });

        this.createFormGroup();
        this.createQuestionItemObject();
    }

    onAutocompleteChange(alias) {
        const item = this.autocompleteLists.find(c => c.alias === alias);

        if (!item) {
            return;
        }

        const url = item.url;
        this.autocompleteListProvider.getListById(url).subscribe(list => {
            this.list = '';
            for (let i = 0; i < list.length; i++) {
                this.list += list[i].name + '\n';
            }
        });
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    onValidationTypeChanged($event: MatSelectChange): void {
        this.validation_type = $event.value;
        this.form.controls['regex_pattern'].setValue(null);
        this.form.controls['regex_validation_message'].setValue(null);
        this.form.controls['allowed_characters'].setValue(null);
        this.form.controls['disallowed_characters'].setValue(null);
        this.form.controls['default_text'].setValue('');
        if ($event.value === 'Regex') {
            this.form.controls['regex_pattern'].setValidators([Validators.required]);
            this.form.controls['regex_validation_message'].setValidators([Validators.required]);
        } else {
            this.form.controls['regex_pattern'].setValidators([]);
            this.form.controls['regex_validation_message'].setValidators([]);
        }
        if ($event.value === 'AllowedCharacters') {
            this.form.controls['allowed_characters'].setValidators([Validators.required]);
        } else {
            this.form.controls['allowed_characters'].setValidators([]);
        }
        if ($event.value === 'DisallowedCharacters') {
            this.form.controls['disallowed_characters'].setValidators([Validators.required]);
        } else {
            this.form.controls['disallowed_characters'].setValidators([]);
        }
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <MultilinesItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            enable_sentiment_analysis: new FormControl(this.questionItem.enable_sentiment_analysis),
            default_text: new FormControl(this.questionItem.default_text),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            html_class: new FormControl(this.questionItem.html_class),
            max_length: new FormControl(this.questionItem.max_length),
            min_length: new FormControl(this.questionItem.min_length),
            rows: new FormControl(this.questionItem.rows),
            cols: new FormControl(this.questionItem.cols),
            allow_html: new FormControl(!!this.questionItem.allow_html),
            validation_type: new FormControl(this.questionItem.validation_type || this.types[0][0]),
            allowed_characters: new FormControl(this.questionItem.allowed_characters),
            disallowed_characters: new FormControl(this.questionItem.disallowed_characters),
            regex_pattern: new FormControl(this.questionItem.regex_pattern),
            regex_validation_message: new FormControl(this.questionItem.regex_validation_message),
        });
        this.allow_html = this.questionItem.allow_html;
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new MultilinesItem();
        questionItem.item_type = SurveyQuestionType.MULTI_LINE_TEXT;
        questionItem.max_length = control['max_length'].value ? control['max_length'].value : null;
        questionItem.min_length = control['min_length'].value ? control['min_length'].value : null;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.alias = control['alias'].value;
        questionItem.default_text = control['default_text'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.question_text_position =
            control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.rows = control['rows'].value;
        questionItem.cols = control['cols'].value;
        questionItem.allow_html = control['allow_html'].value;
        questionItem.enable_sentiment_analysis = control['enable_sentiment_analysis'].value;
        questionItem.validation_type = control['validation_type'].value;
        questionItem.regex_pattern = control['regex_pattern'].value;
        questionItem.regex_validation_message = control['regex_validation_message'].value;
        questionItem.allowed_characters = control['allowed_characters'].value?.trim();
        questionItem.disallowed_characters = control['disallowed_characters'].value?.trim();

        this.sharedService.share('questionItemSettings', questionItem);
        this.allow_html = questionItem.allow_html;
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {}
}
