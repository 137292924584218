import { SentimentType } from '../../sentiment-type';
import { QuestionItem } from './questionItem';

export class MultilinesItem extends QuestionItem {
    max_length: number;
    min_length: number;
    default_text: string;
    question_text_position: string;
    item_position: string;
    allow_html: boolean;
    rows: number;
    cols: number;
    alias: string;
    answer_text?: string;
    enable_sentiment_analysis: boolean;
    sentiment_type?: SentimentType;
    validation_type: MultiLineValidationTypes;
    allowed_characters?: string;
    disallowed_characters?: string;
    regex_validation_message?: string;
    regex_pattern?: string;
}

export enum MultiLineValidationTypes {
    None = 'None',
    AllowedCharacters = 'AllowedCharacters',
    DisallowedCharacters = 'DisallowedCharacters',
    Regex = 'Regex',
}
